import React from 'react';
import PropTypes from 'prop-types';

export const IbdLayout = ({ children, sublayout }) => {
  return (
    <div
      id="InnovationByDesign__anniversary"
      className={`${sublayout ? sublayout : ''}`}
    >
      {children}
    </div>
  )
};

IbdLayout.propTypes = {
  children: PropTypes.any
}