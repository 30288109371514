import React from 'react';
import PropTypes from 'prop-types';

export const BestWorkPlacesLayout = ({ children }) => {
  return (<div id="BestWorkPlacesPage">{children}</div>)
};

BestWorkPlacesLayout.propTypes = {
  children: PropTypes.any
}
