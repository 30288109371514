import React from 'react';
import PropTypes from 'prop-types';

export const MicApplyLayout = ({ children }) => {
  return (<div id="MicApplyLayout__Application">{children}</div>)
};

MicApplyLayout.propTypes = {
  children: PropTypes.any
}
