import React from 'react';
import PropTypes from 'prop-types';

export const WciHubLayout = ({ children }) => {
  return (<div id="wci__hub__layout">{children}</div>)
};

WciHubLayout.propTypes = {
  children: PropTypes.any
}
