import React from 'react';
import PropTypes from 'prop-types';

export const FranchisePageLayout = ({ children, modifier }) => {
  return (<div id="FranchisePage" className={modifier}>{children}</div>)
};

FranchisePageLayout.propTypes = {
  children: PropTypes.any,
  modifier: PropTypes.string
}