import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const BrandsThatMatter = ({ children, year, splash }) => {
  return (
    <div
      id={`BrandsThatMatter__${splash ? 'splash' : 'lander'}`}
      className={clsx('brands-that-matter', {
        'splash': splash,
        [`year--${year || 'current'}`]: year
      })}
    >
      {children}
    </div>
  );
};

BrandsThatMatter.propTypes = {
  children: PropTypes.any
}